<template>
  <div class="payment-gateway-transactions" :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'transactionsPaymentGateway'}]">
    <draggable-dynamic-table ref="paymentGatewayTransactions"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :in-modal="paymentGatewayId > 0"
                             @load:more="getTransactions()"/>

    <button id="printPaymentGatewayTransactions" v-show="false" @click="handlePrintPaymentGatewayTransactions"></button>
  </div>
</template>

<script>
import axios from "axios";
import {getPaymentGatewayTransactions, printPaymentGatewayTransactions} from '../../../../../../http/requests/treasury/paymentGateways'
import {convertUtcToLocale, getTimeFromServer, hideLoading, showLoading} from "../../../../../../assets/js/functions";
import setDocumentDescription from '../../../../../../mixins/setDocumentDescription'

export default {
  name: 'paymentGatewayTransactions',
  metaInfo () {
    return {
      title: this.$t('treasury.paymentGateways.transactions.title')
    }
  },
  props: {
    paymentGatewayId: {
      type: Number,
      default: 0
    }
  },
  mixins: [setDocumentDescription],
  data () {
    return {
      options: {
        id: 'paymentGatewayTransactionsList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'treasury.paymentGateways.transactions.table.header.balance',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'price',
          i18n: 'treasury.paymentGateways.transactions.table.header.price',
          width: '120px',
          minWidth: 120,
          sortable: true,
          locked: true
        },
        {
          field: 'description',
          i18n: 'treasury.paymentGateways.transactions.table.header.description',
          width: '130px',
          minWidth: 130,
          locked: true,
          sortable: true
        },
        {
          field: 'date',
          i18n: 'treasury.paymentGateways.transactions.table.header.date',
          align: 'center',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'treasury.paymentGateways.transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          sortable: true,
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      bank: null,
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: [
        {
          toolbar: [],
          leftToolbar: [
            {
              id: 'printTable',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            },
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'transactionsPaymentGateway' ? 'paymentGateway' : 'paymentGateways'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.$route.name === 'paymentGateway') {
      this.actions[0].toolbar.push({
        id: 'editPaymentGateway',
        icon: 'EDIT',
        iconPack: 'useral',
        color: 'warning',
        permission: 'payment_gateway.update'
      })
    }
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)
    this.$store.dispatch('setPageTitle', this.$t('treasury.paymentGateways.transactions.title'))

    // this.$vs.loading()
    this.getTransactions()
  },
  methods: {
    getTransactions () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.paymentGatewayTransactions && this.data.length === 0) {
            this.$refs.paymentGatewayTransactions.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.paymentGatewayTransactions && this.data.length > 0) {
            this.$refs.paymentGatewayTransactions.loadMoreStatus = 'Loading'
          }

          getPaymentGatewayTransactions(this.paymentGatewayId || this.$route.params.id, this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0

            const transactions = response.data.data
            transactions.forEach(transaction => {
              this.data.push({
                docNumber: {
                  value: transaction.document.id,
                  route: {name: 'document', params: {id: transaction.document.id}},
                  target: '_blank'
                },
                date: {
                  value: convertUtcToLocale(transaction.document.date, this.$localeValidator('moment.dateTime')),
                  classes: this.$vs.rtl ? 'rtl' : 'ltr'
                },
                description: this.generateDocumentDescription(transaction.document, {hasRoute: true}),
                price: {
                  type: 'price',
                  classes: transaction.price < 0 ? 'text-danger' : 'text-success',
                  value: Math.abs(transaction.price) || 0
                },
                balance: {
                  type: 'price',
                  classes: transaction.balance < 0 ? 'text-danger' : 'text-success',
                  value: Math.abs(transaction.balance)
                }
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('docNumber')

              this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
            }

            if (this.$refs.paymentGatewayTransactions) this.$refs.paymentGatewayTransactions.loadMoreStatus = ''
          }).catch((error) => {
            if (this.$refs.paymentGatewayTransactions && !axios.isCancel(error)) this.$refs.paymentGatewayTransactions.loadMoreStatus = 'Danger'
          })
        }
      }, 500)
    },

    handlePrintPaymentGatewayTransactions () {
      showLoading()

      printPaymentGatewayTransactions(this.paymentGatewayId, this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `paymentGatewayTransactionList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('treasury.paymentGateways.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-gateway-transactions {
  &:not(.fixed-draggable-dynamic-table-wrapper-height) {
    height: 100%;
  }
}
</style>
